<template>
  <v-container fluid>
    <FilterDrawer
      current-component="budgets-filter"
      @filter="filterBudgets"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.number }}</td>
        <td>
          {{ item?.client?.company_name }}
        </td>
        <td>
          {{ item?.contact }}
        </td>
        <td class="text-center">
          <StatusChip
            :status="budgetStatus"
            :current-status="item.budget_status"
          />
        </td>
        <td>{{ item.seller }}</td>
        <td>{{ item.cancel_description }}</td>
        <td>{{ item.company }}</td>
        <td>{{ item.origin_create }}</td>
        <td class="text-right">
          {{ item.created_at }}
        </td>
        <td class="text-right">
          {{ item.sended_at }}
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import { mapGetters } from 'vuex'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    budgetStatus: [
      { value: 90, text: 'Aguardando', color: 'blue-grey' },
      { value: 1, text: 'Enviado', color: 'cyan' },
      { value: 2, text: 'Aprovado', color: 'success' },
      { value: 3, text: 'Reprovado', color: 'error' },
      { value: 4, text: 'Abrir CRM', color: 'warning' },
    ],
    dataTable: {
      title: 'Orçamentos',
      new: 'budgets-new',
      edit: 'budgets-edit',
      context: 'budgets-context',
      massAction: 'budgets-mass-action',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: false,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'N°', value: 'number' },
        { text: 'Cliente', value: 'budget_client_name' },
        { text: 'Contato', value: 'budget_client_contact' },
        { text: 'Status', value: 'budget_status', align: 'center' },
        { text: 'Vendedor', value: 'seller' },
        { text: 'Motivo do Cancelamento', value: 'cancel_description' },
        { text: 'Empresa', value: 'company' },
        { text: 'Origem', value: 'origin_create' },
        { text: 'Data de criação', value: 'created_at', align: 'end' },
        { text: 'Data de envio', value: 'sended_at', align: 'end' },
      ],
    },
  }),
  computed: {
    ...mapGetters('comercial', ['showCreateBudgets']),
  },
  created() {
    this.getBudgets()

    contextMenu.$on('action', (type) => {
      if (type === 'budgets') {
        this.getBudgets()
      }
    })
    massAction.$on('action', () => {
      this.getBudgets()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getBudgets(page)
    },
    search(search) {
      this.currentSearch = search
      this.getBudgets()
    },
    getBudgets(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('budgets', {
          params: {
            page,
            search: this.currentSearch,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
          this.dataTable.totalPages = res.data.total_pages
          this.dataTable.totalRegister = res.data.total_budgets
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterBudgets(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('budgets', {
          params: {
            ...filter,
            page: 1,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
          this.dataTable.totalPages = res.data.total_pages
          this.dataTable.totalRegister = res.data.total_budgets
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getBudgets()
    },
  },
}
</script>
